import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam,SearchFilterComponent } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { ShopDetails } from './models/shopDetails.model';
import { ShopsService } from './shops.service';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/auth/permission.service';
import { ShopMessageDialogComponent } from './shop-message-dialog/shop-message-dialog.component';
@Component({
  selector: 'app-dialog-popup',
  templateUrl: './shop-status.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopStatusComponent {
  dataShopStatusProvider: any;
  statusTitle: string = '';
  shopPauseTime: any;
  pauseTimeIntervalArray!: any[];
  pauseTimeIntra!: number;
  permission: any;
  constructor(
    private fb: FormBuilder,
    private shopsService: ShopsService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    private stateManagerService: StateManagerService,
    private permissionService:PermissionService,
    public dialogRef: MatDialogRef<ShopStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public dataShopStatus: any) {

    this.dataShopStatusProvider = this.dataShopStatus.shop;
    this.permission = this.permissionService.getPagePermission('shops');

    // Debugging the initial value of dataShopStatus
    console.log('dataShopStatus:', this.dataShopStatus);
    console.log('dataShopStatus.is_pause:', this.dataShopStatus.is_pause);
    this.dataShopStatusProvider = this.dataShopStatus.shop;
    if (!this.dataShopStatus.is_pause) {
      this.shopPauseTime = JSON.parse(this.dataShopStatus.shop.oo_shop_pause_time || '[]')[0];
      this.shopPauseTime.fromTimeFormatted = moment(this.shopPauseTime.from_time, "HH:mm:ss").format("LT");
    } else {
      this.pauseTimeIntra = 10;
      this.getOoPauseTimeArray();
    }
  }

  ngOnInit() {
    // Any initialization code can go here
  }

  actionCancel(): void {
    this.dialogRef.close();
  }

  getOoPauseTimeArray() {
    let val = 10;
    this.pauseTimeIntervalArray = [];
    for (let i = val; i <= 12 * val; i += val) {
      this.pauseTimeIntervalArray.push(i);
    }
  }

  pauseStopClick() {
    const activeUser: any = this.auth.getUser();
    const pauseParamData = {
      id: this.shopPauseTime.id,
      shopId: this.shopPauseTime.shop_id,
      date: this.shopPauseTime.date,
      orgin: 'HQ',
      fromTime: this.shopPauseTime.from_time,
      toTime: moment(new Date()).format("HH:mm:00"),
      createdBy: 0,
      updatedBy: 0
    };

    this.shopsService.createPauseTime(pauseParamData).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.dialogRef.close();
          this.dataShopStatus.shop.hasPauseTime = 0; // Assign value instead of comparison
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }

  createPause() {
    const activeUser: any = this.auth.getUser();
    const pauseData = {
      shopId: this.dataShopStatusProvider.shop_id,
      date: moment(new Date()).format("YYYY-MM-DD"),
      orgin: 'HQ',
      fromTime: moment(new Date()).format("HH:mm:00"),
      toTime: moment().add(this.pauseTimeIntra, 'minutes').format('HH:mm:00'),
      createdBy: 0,
      updatedBy: 0
    };

    this.shopsService.createPauseTime(pauseData).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.dialogRef.close();
          this.dataShopStatus.shop.hasPauseTime = 1; // Assign value instead of comparison
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }
}


@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrl: './shops.component.scss'
})
export class ShopsComponent {
  shopsList: ShopDetails[]=[];
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "code",
    },
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Bento",
      column: "has_bento",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
    {
      title: "Sushi",
      column: "has_sushi",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
    
    {
      title: "Status",
      column: "has_pause_time",
      type: "list",
      values: [
        { title: "ON SALE", value: "0" },
        { title: "PAUSED", value: "1" },

      ]
    }, {
      title: "Valid",
      column: "is_valid",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
   

  ];

  displayedColumns = ['item', 'code', 'name','hasBento','hasSushi','OOStatus','action'];
  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: ShopDetails;
  dataSource = new MatTableDataSource(this.shopsList);
  permission: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private shopsService: ShopsService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private stateManagerService: StateManagerService,
    private router: Router,private permissionService:PermissionService) {

    this.shopsList = [];
    this.permission = this.permissionService.getPagePermission('shop');

  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });


    //this.loadData();

  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  buildJWSRequsetData(requestData: RequestData): any {
    var jwsRequestData: {
      search: {
        filters: {
          filter: {
            column: string;
            operator: string;
            value: any;
          }[], scope: { offset: number, limit: number }
        }, sort: []
      }
    } = {
      search: {
        filters: {
          filter:
            [], scope: { offset: 0, limit: 10 }
        }, sort: []
      }
    }
    var offset = requestData.search?.filters?.scope?.offset;
    var limit = requestData.search?.filters?.scope?.limit;
    jwsRequestData.search.filters.scope.offset = (offset != undefined) ? offset : 0;
    jwsRequestData.search.filters.scope.limit = (limit != undefined) ? limit : 0;

    if (requestData.search?.filters?.simpleSearch != undefined) {
      var searchValue = requestData.search?.filters?.simpleSearch[0].value;
      var nameFilter = { column: "name", operator: "like", value: searchValue };
      var codeFilter = { column: "code", operator: "like", value: searchValue };
      jwsRequestData.search.filters.filter.push(nameFilter);
      jwsRequestData.search.filters.filter.push(codeFilter);


    }

return jwsRequestData;
  }

  directToImportShop(): any {
    this.router.navigate(['import-shop']);
  }

  /**
   * loads the items data based on the conditions
   */
  // loadData(): void {
  //   this.isLoading = true;
  //   let requestData = this.buildRequestData();
  //   let response = this.shopsService.getShopListExt(requestData).subscribe(response => {
  //     if (response.status == 'SUCCESS') {
  //       this.shopsList = response.data.shopInfo;
  //       this.pagingData.length = response.data.total_count;
  //       if (this.pagingData.length == 0) {
  //         let snackBarData: SnackBarData = {
  //           message: 'No data found. Please remove or change the filter if any.',
  //           title: 'No Data!!!',
  //           type: 'warn'
  //         }
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     } else {
  //       let snackBarData: SnackBarData = {
  //         message: response.message,
  //         title: 'Failed!!!',
  //         type: 'error'
  //       }
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //     this.isLoading = false;
  //   });

  // }
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    // var jwsRequestData = this.buildJWSRequsetData(requestData);
    let response = this.shopsService.getShopListExt(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.setDataSource(response.data.shopInfo );
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        } else {
          this.stateManagerService.setState(this.constructor.name, requestData);
          // if(this.columnSortData!=undefined && this.columnSortData.length>0){
          //   var sort=this.columnSortData[0];
          //    this.setSortColumn(sort.column,sort.order);
          // }
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }
 /** 
  * Set the datasource
  */
 setDataSource(shopsList:ShopDetails[]){
  this.dataSource=new MatTableDataSource(shopsList);
  this.dataSource.sort=this.sort;
}

@ViewChild(MatSort) sort!: MatSort;
@ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
ngAfterViewInit(): void {

  this.loadPreviousState();
  // this.loadData();
}

/**
 * loads the previous state if exists
 */
loadPreviousState(): void {

  var previousState = this.stateManagerService.getState(this.constructor.name);

  if (previousState !== undefined && this.searchFilter != undefined) {

    if (previousState.state.search.filters.scope) {
      this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
      this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
    }

    if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
      this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
    }

    this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
    this.searchFilter.setSearchFilter(this.searchColumFilter,true);

  } else {
    this.setSortColumn('name','asc');
    this.loadData();
  }

}

/**
 * Sets the sort column
 * @param column 
 * @param direction 
 */
setSortColumn(column: string, direction: SortDirection): void {
  this.columnSortData.length = 0;
  this.columnSortData.push({ column: column, order: direction});
  this.sort.sort({
    id: column,
    start: direction,
    disableClear: false,
  });

  // this.sort.active=column;
  // this.sort.direction=direction;
  

}


  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new items
   */
  addClicked(): void {

    this.router.navigate(['shop-edit', 0]);
  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }


  /**
  * On edit the item
  * Show the edit dialog
  */
  onEdit(shopInfo: ShopDetails): void {
    if (shopInfo && shopInfo.id !== undefined) {
      this.router.navigate(['shop-edit', shopInfo.id]);
    } else {
      // Handle the case where the id is undefined or not present
      console.error('Invalid shopInfo object for editing:', shopInfo);
      // Optionally, display a message to the user or log the issue
    }
  }



  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

 

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On items selected
 * @param itemsInfo 
 */
  onRowSelected(shopsInfo: ShopDetails): void {
    this.selectedItem = shopsInfo;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }
  changePause(shop: any){
    if (!this.permission.can_execute) {
      this.displayErrorDialog('You do not have permission to resume online ordering.');
    return; 

    }

    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '35%';

    dialogConfig.data = {
      'shop': shop,
      'is_pause': false,
      'title': 'Resume Online Ordering',
    };
    const dialogRef = this.dialog.open(ShopStatusComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }
  displayErrorDialog(message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "400px";
    dialogConfig.height = "160px";
    dialogConfig.data = { message }; // Pass the error message as data
    const dialogRef = this.dialog.open(ShopMessageDialogComponent, dialogConfig); // Use a separate component for error display
  }

  changeOnSale(shop:any){
    if (!this.permission.can_execute) {
      this.displayErrorDialog('You do not have the rights to perform this action.');
    return; 

    }
    
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '35%';

    dialogConfig.data = {
      'shop': shop,
      'is_pause': true,
      'title': 'Pause Online Ordering',
    };
    const dialogRef = this.dialog.open(ShopStatusComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }
  exportClicked() {
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
  
    this.shopsService.downloadExcel(requestData)
      .subscribe(
        blob => {
          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'shop.xlsx'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.IsDownloading = false;
        },
        error => {
          // Handle errors
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);          
          this.isLoading = false;
          this.IsDownloading = false;
          // Display user-friendly error message to the user
        }
      );
  }

}


