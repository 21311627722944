import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { CustomerService } from "./customer.service";
import { formatDate } from '@angular/common';
import { AuthService } from "src/app/auth/auth.service";
import { FilterService } from "src/app/common/components/search-filter/filter.service";
import { AppConstants } from "src/app/common/app.constants";
import { CustomerModel } from "./models/customer.model";
import { SystemSettingService } from "src/app/common/services/system-setting.service";
import { CustomerViewComponent } from "./customer-view/customer-view.component";
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from "src/app/auth/permission.service";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss'
})


export class CustomerComponent {
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;
  filter: FilterListItemModel[] = [
    {
      title: "Name",
      column: "CONCAT_WS('',v_oo_admin_customer_details_ext.first_name, ' ', v_oo_admin_customer_details_ext.last_name )",
     
    },
    // {
    //   title: "First Name",
    //   column: "v_oo_admin_customer_details_ext.first_name",
    // },
    // {
    //   title: "Last Name",
    //   column: "v_oo_admin_customer_details_ext.last_name",
    // },
    {
      title: "Email",
      column: "v_oo_admin_customer_details_ext.email",
    },
    {
      title: "Order Count",
      column: "v_oo_admin_customer_details_ext.order_count",
    },

    {
      title: "Phone Number",
      column: "v_oo_admin_customer_details_ext.phone_number",
    },
    {
      title: "Total Amount",
      column: "v_oo_admin_customer_details_ext.total_amount",
      type: "number",
      operators: [
        { title: "is", operator: "=" },
        { title: ">=", operator: ">=" },
        { title: "<=", operator: "<=" },

      ],

    },
    {
      title: "Order Date",
      column: "v_oo_admin_customer_details_ext.last_order_date",
      type: "date",
      operators: [
        { title: "is", operator: "=" },
        { title: ">=", operator: ">=" },
        { title: "<=", operator: "<=" },

      ],
    },
    {title: "Active",
      column: "is_valid",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
    
  ];

  displayedColumns = [
    "item",
    "Given Name",
    "Email Address",
    "Phone",
    "Active",
    "Last Order Date",
    "Orders",
    "Total Amount",
    "action"
  ];
  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: CustomerModel;
  //customerList: any;
  customerList: CustomerModel[]=[];
  dateFormat: string;
  dataSource = new MatTableDataSource(this.customerList);
  permission: any;
  constructor(private breakpointObserver: BreakpointObserver,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router,
    private systemSettingService: SystemSettingService, private stateManagerService: StateManagerService, private permissionService: PermissionService,
  ) {

    this.customerList = [];
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.permission = permissionService.getPagePermission('customer');
  }

  /**
  * initilaize the device model
  */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });


   // this.loadData();

  }
  
  /** 
  * Set the datasource
  */
  setDataSource(customerList:CustomerModel[]){
    this.dataSource=new MatTableDataSource(customerList);
    this.dataSource.sort=this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('v_oo_admin_customer_details_ext.first_name','asc');
      this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
   * loads the customer data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    let response = this.customerService.getCustomersList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.customerList = response.data.customers;
        this.setDataSource(response.data.customers );
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }else {
          this.stateManagerService.setState(this.constructor.name, requestData);
          // if(this.columnSortData!=undefined && this.columnSortData.length>0){
          //   var sort=this.columnSortData[0];
          //    this.setSortColumn(sort.column,sort.order);
          // }
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  /**
  * Method is use to show customer detail view.
  
  */

  customerDetail(data: any): void {
    const dialogRef = this.dialog.open(CustomerViewComponent, {
      width: '90%', // Adjust the width as needed
      height: '90%',
      data: { customerInfo: data },
    });


  }
  
  /**
  * On edit the item
  * Show the edit dialog
  */
  onEdit(customerInfo: CustomerModel): void {
    if (customerInfo && customerInfo.id !== undefined) {
      this.router.navigate(['customer-edit', customerInfo.id]);
    } else {
      // Handle the case where the id is undefined or not present
      console.error('Invalid itemsInfo object for editing:', customerInfo);
      // Optionally, display a message to the user or log the issue
    }
  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new items
   */
  addClicked(): void {

    this.router.navigate(['item-items-edit', 0]);
  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }
  /**
 *  Set the sort param and relead the data
 * @param $event sort data
 * {"active": "email","direction": "asc"}
 *   
 */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On items selected
 * @param itemsInfo 
 */
  onRowSelected(customerInfo: CustomerModel): void {
    this.selectedItem = customerInfo;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

  /**
* Set the column filter and reaload the data
* @param columFilter 
*/

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }
  /**
 * export cusomer list
 * 
 */


  exportData(): void {
    this.IsDownloading = true;
    this.isLoading = true;
    const params = this.buildRequestData();

    this.customerService.exportCustomerList(params)
      .subscribe(
        blob => {

          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const currTime = formatDate(new Date(), 'yyyyMMddhhmmss', 'en-US', '+0530');
          link.download = 'oo-customer-list-export' + currTime + '.csv'; // Adjust filename if needed

          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.IsDownloading = false;
        },
        error => {
          // Handle errors
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);          
          this.isLoading = false;
          this.IsDownloading = false;
          // Display user-friendly error message to the user
        }
      );
  }

  /**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
  downLoadFile(data: any, type: string): any {

    const headers = data.headers;
    const contentDisposition = headers.get('content-disposition');
    const blob = new Blob([data.body], { type: type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = contentDisposition;
    anchor.href = url;
    anchor.click();
    this.isLoading = false;

  }


}
