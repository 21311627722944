import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  constructor(private http: HttpClient, private datePipe: DatePipe) {


  }

  /**
  * Today's sales data
  */
  getTodaySales(): Observable<any> {
    const today = new Date();
    const startDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    return this.http.post(environment.wsPhp + "/dashboard/get-sales-summary", { startDate: startDate, endDate: startDate });
  }

  /**
  *  Ths week sales data
  */
  getThisWeekSales(): Observable<any> {

    const today = new Date();
    const dayOfWeek = today.getDay();
    const diffToMonday = (dayOfWeek + 6) % 7;
    const monday = new Date(today);
    monday.setDate(today.getDate() - diffToMonday);
    const sunday = new Date();
    sunday.setDate(monday.getDate() + 6);
    const startDate = this.datePipe.transform(monday, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(sunday, 'yyyy-MM-dd');
    return this.http.post(environment.wsPhp + "/dashboard/get-sales-summary", { startDate: startDate, endDate: endDate });
  }

  /**
   * This month sales data
   * @returns 
   */
  getThisMonthSales(): Observable<any> {

    const today = new Date();
    const monStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const monEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startDate = this.datePipe.transform(monStartDate, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(monEndDate, 'yyyy-MM-dd');
    return this.http.post(environment.wsPhp + "/dashboard/get-sales-summary", { startDate: startDate, endDate: endDate });
  }

  /**
   * This year sales data
   * @returns 
   */
  getThisYearSales(): Observable<any> {

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = (currentMonth>=4)?today.getFullYear():today.getFullYear()-1;
    const nextYear=(currentMonth>=4)?today.getFullYear()+1:today.getFullYear();
    const startDate = currentYear+"/04/01";
    const endDate = nextYear+"/03/31";
    return this.http.post(environment.wsPhp + "/dashboard/get-sales-summary", { startDate: startDate, endDate: endDate });
  }

  /**
 * Delivery orders sum by current and previous year
 * @returns 
 */

  getDataByCurrentandPreviousYear(): any {
    return this.http.get<any>(environment.wsPhp + "/dashboard/get-monthly-sales");
  }

  /**
* Top Ten Products List
* @returns 
*/

  getTopTenProducts(): any {
    return this.http.get<any>(environment.wsPhp + "/dashboard/get-top-ten-products");
  }

}
